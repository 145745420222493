import React from 'react';
// Structure imports
import Layout from '@components/structure/Layout';
// Utility imports
import Seo from '@components/utility/Seo';
import CtaButton from '@mui-components/ctaButton';
import { Grid } from '@mui/material';
import { StaticImage } from 'gatsby-plugin-image';
import { Link } from 'gatsby';

const RegConfirmationIhUnbranded = () => (
	<Layout
		pageType='ih-unbranded'
		className='idiopathicHypersomniaUnbranded no-tabs'
		pixelCode='Complete Registration'>
		<Seo
			title='Confirmation of Webinar Registration | Jazz Webinars'
			description=''
		/>
		<div>
			<StaticImage
				className='test-desktop'
				src='../../../static/images/ih-unbranded-hero.png'
				alt='Idiopathic hypersomnia Hero Banner Desktop on confirmation page'
			/>
			<StaticImage
				className='test-mobile '
				src='../../../static/images/ih-unbranded-hero-mobile.png'
				alt='Idiopathic hypersomnia Hero Banner Mobile on confirmation page'
			/>
			<section className='container firstSection'>
				<Grid container>
					<Grid item xs={12}>
						<div className='lv-copy'>
							<h1>
								<span>Stay up-to-date on</span>
								The Symptoms <br class='desktopOnly' />
								and Impact of <br class='desktopOnly' />
								Idiopathic <br class='desktopOnly' />
								Hypersomnia (IH)
							</h1>
						</div>
					</Grid>
				</Grid>
			</section>
		</div>
		<section className='smaller-container center'>
			<h2>Thanks for signing up!</h2>
			<div className='gradient-divider center'>
				<hr />
			</div>
			<p className='copy'>
				We’re looking forward to having you in our live webinar session(s). A
				confirmation email will be sent to your inbox with the details of the
				webinar(s) you've signed up for.
			</p>
			<p>
				<strong>
					In the meantime, why not browse through our Webinars On-Demand video
					library?
				</strong>
			</p>

			<Link
				to={`/idiopathic-hypersomnia`}
				state={{ onDemandTabSelected: true }}
				className='cta watch-cta rounded-link'>
				Watch Webinars On Demand
			</Link>

		</section>
	</Layout>
);

export default RegConfirmationIhUnbranded;
